.about-me {
    height: 100vh;
    display: grid;
    text-align: justify;
}

.book-col {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}

.book-img {
    max-width: 100%;
    height: auto;
    width: 10em;
    height: 10em;
}

@media (max-width: 575.98px) {
    .about-me {
        height: auto;
        display: block;
    }

    .book-col {
        flex-direction: column;
        margin-bottom: 10px;
    }

    .book-img {
        width: 8em;
        height: 8em;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .about-me {
        height: auto;
        display: block;
    }

    .book-col {
        flex-direction: column;
        margin-bottom: 12px;
    }

    .book-img {
        width: 9em;
        height: 9em;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .about-me {
        height: auto;
        display: block;
    }

    .book-col {
        flex-direction: column;
        margin-bottom: 12px;
    }

    .book-img {
        width: 9em;
        height: 9em;
    }

    .col-md-8 {
        flex-shrink: 0;
        width: 100%;
    }

    .col-md-4 {
        flex-shrink: 0;
        width: 100%;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .about-me {
        height: auto;
        display: grid;
    }

    .book-col {
        flex-direction: row;
        margin-bottom: 15px;
    }

    .book-img {
        width: 10em;
        height: 10em;
    }

    .col-md-8 {
        flex: 0 0 auto;
        width: 60%;
    }

    .col-md-4 {
        flex: 0 0 auto;
        width: 40%;
    }
}

@media (min-width: 1200px) {
    .about-me {
        height: auto;
        display: grid;
    }

    .book-col {
        flex-direction: row;
        margin-bottom: 15px;
    }

    .book-img {
        width: 10em;
        height: 10em;
    }
}