.sidebar {
    width: 18%;
    border-right: 1px solid #000;
    padding: 1%;
    position: fixed;
    height: 100vh;
    overflow-y: auto;
    background-color: rgb(17 20 24) !important;
}

.profile-picture {
    width: 100%;
    overflow: hidden;
}

.profile-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.menu {
    display: flex;
    flex-direction: column;
}

.menu-item {
    padding: 10px;
    margin-bottom: 10px;
    text-align: center;
    cursor: pointer;
    transition: transform 0.3s ease, opacity 0.3s ease;
}

.menu-item.selected {
    background-color: #888;
    color: #fff;
}

.menu-item:hover {
    transform: scale(1.1);
    background-color: #8888886a;
    opacity: 0.8;
}


@media (max-width: 575.98px) {
    .sidebar {
        width: 0vh;
        padding: 0%;
    }

    .menu {
        flex-direction: row;
        overflow-x: auto;
    }

    .menu-item {
        padding: 5px;
        margin-bottom: 0;
        margin-right: 10px;
        text-align: left;
        font-size: 14px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .sidebar {
        width: 0vh;
        padding: 0%;
    }

    .menu {
        flex-direction: row;
        overflow-x: auto;
    }

    .menu-item {
        padding: 7px;
        margin-bottom: 0;
        margin-right: 10px;
        text-align: left;
        font-size: 16px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .sidebar {
        width: 25%;
        height: 100vh;
        position: fixed;
        border-right: 1px solid #000;
        border-bottom: none;
    }

    .menu {
        flex-direction: column;
    }

    .menu-item {
        padding: 10px;
        margin-bottom: 10px;
        text-align: center;
        font-size: 18px;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .sidebar {
        width: 20%;
        height: 100vh;
        position: fixed;
        border-right: 1px solid #000;
        border-bottom: none;
    }

    .menu {
        flex-direction: column;
    }

    .menu-item {
        margin-bottom: 10px;
        text-align: center;
        font-size: 18px;
    }

    .section {
        padding: 0;
    }
}

@media (min-width: 1200px) {
    .sidebar {
        width: 18%;
        height: 100vh;
        position: fixed;
        border-right: 1px solid #000;
        border-bottom: none;
    }

    .menu {
        flex-direction: column;
    }

    .menu-item {
        padding: 10px;
        margin-bottom: 10px;
        text-align: center;
        font-size: 18px;
    }
}