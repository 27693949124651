.experience {
    height: 100vh;
    display: grid;
    align-items: center;
}

.experience-item p {
    font-style: italic;
    color: #777;
}

.experience-item ul {
    margin: 0;
    padding-left: 20px;
    list-style-type: disc;
}

.experience-item li {
    margin-bottom: 10px;
}

@media (max-width: 575.98px) {
    .experience {
        height: auto;
        padding: 1em;
    }

    .experience-item ul {
        padding-left: 15px;
    }

    .experience-item li {
        margin-bottom: 8px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .experience {
        height: auto;
        padding: 1.5em;
    }

    .experience-item ul {
        padding-left: 18px;
    }

    .experience-item li {
        margin-bottom: 9px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .experience {
        height: auto;
        padding: 2em;
    }

    .experience-item ul {
        padding-left: 20px;
    }

    .experience-item li {
        margin-bottom: 10px;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .experience {
        height: auto;
    }

    .experience-item ul {
        padding-left: 20px;
    }

    .experience-item li {
        margin-bottom: 10px;
    }
}

@media (min-width: 1200px) {
    .experience {
        height: auto;
        padding: 2em;
    }

    .experience-item ul {
        padding-left: 20px;
    }

    .experience-item li {
        margin-bottom: 10px;
    }
}