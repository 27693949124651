.skills {
    height: 100vh;
    display: grid;
    text-align: justify;
    background-size: cover;
}

.skills-item {
    margin-bottom: 20px;
}

.skills-item p {
    font-style: italic;
    color: #777;
}

.skill-icons {
    margin-bottom: 10px;
}

.skill-icons .mr-3 {
    margin-right: 10px;
}

.skills-row {
    display: flex;
    justify-content: space-between;
}

.skills-col {
    flex: 1;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
}

.skills-col-item {
    display: grid;
}

@media (max-width: 575.98px) {
    .skills {
        height: auto;
        padding: 1em;
    }

    .skills-item {
        margin-bottom: 15px;
    }

    .skills-item p {
        font-size: 14px;
    }

    .skill-icons .mr-3 {
        margin-right: 5px;
    }

    .skills-row {
        flex-direction: column;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .skills {
        height: auto;
        padding: 1em;
    }

    .skills-item {
        margin-bottom: 18px;
    }

    .skills-item p {
        font-size: 15px;
    }

    .skill-icons .mr-3 {
        margin-right: 8px;
    }

    .skills-row {
        flex-direction: column;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .skills {
        height: auto;
        padding: 1.5em;
    }

    .skills-item {
        margin-bottom: 20px;
    }

    .skills-item p {
        font-size: 16px;
    }

    .skill-icons .mr-3 {
        margin-right: 10px;
    }

    .skills-row {
        flex-direction: column;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .skills {
        height: auto;
        padding: 0;
    }

    .skills-item {
        margin-bottom: 20px;
    }

    .skills-item p {
        font-size: 17px;
    }

    .skill-icons .mr-3 {
        margin-right: 10px;
    }

    .skills-row {
        flex-direction: column;
    }
}

@media (min-width: 1200px) {
    .skills {
        height: auto;
        padding: 2em;
    }

    .skills-item {
        margin-bottom: 20px;
    }

    .skills-item p {
        font-size: 18px;
    }

    .skill-icons .mr-3 {
        margin-right: 10px;
    }
}