.container {
  display: flex;
  margin: 0 !important;
  padding: 0 !important;
  max-width: 100% !important;
  color: #83f5d3 !important;
}

.main-content {
  margin-left: 18%;
}


.section {
  padding: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #252b33;
}

.pl-4 {
  padding-left: 1.5rem;
}

.text-light-green {
  color: #20c997 !important;
}

.text-important {
  color: #ae46ee !important;
}

.text-5 {
  font-size: 1.3125rem !important;
  line-height: 1.3;
}

.text-19 {
  font-size: calc(1.65rem + 4.8vw) !important;
}

@media (min-width: 1200px) {
  .text-19 {
    font-size: 5.25rem !important;
  }
}

.text-light {
  color: #dee3e4 !important;
}

.opacity-4 {
  opacity: 0.4;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.w-100 {
  width: 100% !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.bg-dark-1 {
  background-color: #212529 !important;
}

.bg-dark-2 {
  background-color: #343a40 !important;
}

.section a {
  color: blue;
  text-decoration: none;
}



@media (max-width: 575.98px) {
  .main-content {
    margin-left: 0;
  }

  .pl-4 {
    padding-left: 1rem;
  }

  .section {
    padding: 10px;
  }

  .text-5 {
    font-size: 1.125rem !important;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .main-content {
    margin-left: 0;
  }

  .pl-4 {
    padding-left: 1.25rem;
  }

  .section {
    padding: 15px;
  }

  .text-5 {
    font-size: 1.1875rem !important;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .main-content {
    margin-left: 25%;
  }

  .pl-4 {
    padding-left: 1.25rem;
  }

  .section {
    padding: 15px;
  }

  .text-5 {
    font-size: 1.25rem !important;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .main-content {
    margin-left: 20%;
  }

  .pl-4 {
    padding-left: 1.5rem;
  }

  .section {
    padding: 20px;
  }

  .text-5 {
    font-size: 1.3125rem !important;
  }
}

@media (min-width: 1200px) {
  .main-content {
    margin-left: 18%;
  }

  .pl-4 {
    padding-left: 1.5rem;
  }

  .section {
    padding: 20px;
  }

  .text-5 {
    font-size: 1.3125rem !important;
  }
}