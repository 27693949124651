.contact {
    height: 80vh;
    padding: 2em;
}

.contact input,
.contact textarea {
    margin: 0 0 1em 0;
    border-radius: 4px;
    border: 1px solid #ddd;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
}

.submit-button {
    align-items: center;
    appearance: none;
    background-color: #20c997;
    border-radius: 4px;
    border-width: 0;
    box-shadow: rgba(45, 35, 66, 0.2) 0 2px 4px, rgba(45, 35, 66, 0.15) 0 7px 13px -3px, #068f66 0 -3px 0 inset;
    box-sizing: border-box;
    color: #36395A;
    cursor: pointer;
    display: inline-flex;
    font-family: "JetBrains Mono", monospace;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding: 16px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow .15s, transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
    font-size: 18px;
    margin-top: 1em;
    width: 5%;
}

.submit-button:focus {
    box-shadow: #068f66 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
}

.submit-button:hover {
    box-shadow: rgba(45, 35, 66, 0.3) 0 4px 8px, rgba(45, 35, 66, 0.2) 0 7px 13px -3px, #068f66 0 -3px 0 inset;
    transform: translateY(-2px);
}

.submit-button:active {
    box-shadow: #068f66 0 3px 7px inset;
    transform: translateY(2px);
}

.text-danger,
.text-success {
    margin-top: 1em;
}

.form-control {
    color: #999 !important;
    background-color: #232a31 !important;
    border-color: #232a31 !important;
}

.form-control::placeholder {
    color: #999 !important;
}

@media (max-width: 575.98px) {
    .contact {
        height: auto;
        padding: 1em;
    }

    .submit-button {
        width: 50%;
        font-size: 16px;
        padding: 14px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .contact {
        height: auto;
        padding: 1.5em;
    }

    .submit-button {
        width: 50%;
        font-size: 16px;
        padding: 14px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .contact {
        height: auto;
        padding: 1.5em;
    }

    .submit-button {
        width: 25%;
        font-size: 17px;
        padding: 15px;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .contact {
        height: auto;
        padding: 2em;
    }

    .submit-button {
        width: 10%;
        font-size: 18px;
        padding: 16px;
    }
}

@media (min-width: 1200px) {
    .contact {
        height: auto;
        padding: 2em;
    }

    .submit-button {
        width: 5%;
        font-size: 18px;
        padding: 16px;
    }
}