.footer {
    height: 20vh;
    align-items: center;
    background-color: #212529;
    padding: 20px;
    text-align: center;
    position: relative;
    bottom: 0;
    display: grid;
    align-items: center;
}

.footer-content {
    max-width: 88%;
    margin: 0 auto;
}

.footer a {
    color: #fff;
    text-decoration: none;
    margin: 0 10px;
    transition: color 0.3s;
}

.footer a:hover {
    color: #7bff47;
}

@media (max-width: 575.98px) {
    .footer {
        height: auto;
        padding: 10px;
    }

    .footer-content {
        max-width: 100%;
    }

    .footer a {
        margin: 0 5px;
        font-size: 14px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .footer {
        height: auto;
        padding: 15px;
    }

    .footer-content {
        max-width: 95%;
    }

    .footer a {
        margin: 0 7px;
        font-size: 16px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .footer {
        height: 20vh;
        padding: 20px;
    }

    .footer-content {
        max-width: 90%;
    }

    .footer a {
        margin: 0 10px;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .footer {
        height: auto;
        padding: 20px;
    }

    .footer-content {
        max-width: 88%;
    }

    .footer a {
        margin: 0 10px;
    }
}

@media (min-width: 1200px) {
    .footer {
        height: auto;
        padding: 20px;
    }

    .footer-content {
        max-width: 88%;
    }

    .footer a {
        margin: 0 10px;
    }
}